<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-12">
          <c-card title="기준정보" class="cardClassDetailForm work-card" :noMarginPadding="true">
            <template slot="card-description">
              <font v-if="workPermit.swpWorkRequestCd" class="text-negative" style="font-size:0.8em;font-weight:300;">
                ({{workRequestDescription}})
              </font>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="!isOld"
                  label="불러오기"
                  icon="system_update_alt"
                  @btnClicked="loadWorkPermit" />
                <c-btn
                  v-show="isWorking"
                  label="출력"
                  icon="print"
                  @btnClicked="printWorkPermit" />
                <!-- [S]결재관련 버튼 -->
                <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
                <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
                <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
                <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
                <!-- <c-appr-btn 
                  ref="appr-btn"
                  name="work-permit-appr-btn"
                  :editable="editable"
                  :approvalInfo="approvalInfo"
                  @beforeApprAction="approvalWorkPermit"
                  @callbackApprAction="approvalWorkPermitCallback"
                  @requestAfterAction="getDetail('save')"
                /> -->
                <!-- '일괄저장' : '취소 - 연장 - 완료 처리' -->
                <c-btn
                  v-show="workPermit.returnRemark"
                  label="반려사유"
                  icon="keyboard_return"
                  @btnClicked="returnDialogOpen" />
                <c-btn
                  v-show="editable && isWriting && isOld && !popupParam.isApprContent && !workPermit.sysApprovalRequestId && !disabled"
                  label="삭제"
                  icon="remove"
                  @btnClicked="removeWorkPermit" />
                <c-btn
                  v-show="editable && !disabled && isWriting" 
                  :isSubmit="isSave"
                  :url="saveUrl"
                  :param="workPermit"
                  :mappingType="mappingType"
                  :label="saveBtnLabel"
                  icon="save"
                  @beforeAction="saveWorkPermit"
                  @btnCallback="saveWorkPermitCallback" />
                <c-btn
                  v-show="editable && isOld && !disabled" 
                  :isSubmit="isRequest"
                  :url="requestUrl"
                  :param="workPermit"
                  mappingType="PUT"
                  :label="requestBtnLabel"
                  icon="check"
                  @beforeAction="requestWorkPermit"
                  @btnCallback="requestWorkPermitCallback" />
                <c-btn
                  v-show="editable && isOld && !rejectDisabled" 
                  :isSubmit="isReject"
                  :url="rejectUrl"
                  :param="workPermit"
                  mappingType="PUT"
                  label="승인요청 취소"
                  icon="check"
                  @beforeAction="rejectWorkPermit"
                  @btnCallback="rejectWorkPermitCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 work-item">
                <c-plant
                  :required="true"
                  :disabled="!isWriting"
                  :editable="editable"
                  label="사업장"
                  name="plantCd"
                  v-model="workPermit.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 work-item">
                <c-text
                  :disabled="true"
                  :editable="editable"
                  label="허가번호"
                  name="permitNo"
                  v-model="workPermit.permitNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 work-item">
                <c-datepicker
                  :required="true"
                  :disabled="!isWriting"
                  :editable="editable"
                  label="허가작업일"
                  name="permitDate"
                  v-model="workPermit.permitDate"
                />
              </div>
              <div class="col-xs-12 col-sm-4 col-md-1 col-lg-1 work-item">
                <c-datepicker
                  :required="true"
                  :disabled="!isWriting"
                  :editable="editable"
                  :minuteStep="10"
                  :disabledTime="disabledWorkStartTime"
                  type="time"
                  label="시작시간"
                  name="workStartTime"
                  v-model="workPermit.workStartTime"
                  @datachange="changeStartTime"
                />
              </div>
              <div class="col-xs-12 col-sm-4 col-md-1 col-lg-1 work-item">
                <c-datepicker
                  :required="true"
                  :disabled="!isWriting"
                  :editable="editable"
                  :minuteStep="10"
                  :disabledTime="disabledWorkEndTime"
                  type="time"
                  label="종료시간"
                  name="workEndTime"
                  v-model="workPermit.workEndTime"
                />
                  <!-- @datachange="changeEndTime" -->
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 work-item">
                <c-multi-select
                  :disabled="!isWriting"
                  :editable="editable"
                  codeGroupCd="SOP_SUPPLEMENT_WORK_TYPE_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="보충작업"
                  name="supWorks"
                  v-model="supWorks"
                  @datachange="changeSupWork">
                </c-multi-select>
              </div>
              <template v-if="isWorkInfo">
                <q-slide-transition >
                  <div v-show="visible" class="row" style="display:-webkit-inline-box;width:100%;">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <q-card class="work-card" flat bordered
                        :class="cancelFlag ? 'bg-highLight' : ''">
                        <q-card-section class="q-pa-xs q-pl-sm bg-grey-3"
                          :class="this.editable&&!this.disabled&&!this.disableCancel ? 'cursor-pointer' : 'cursor-not-allowed'"
                          v-on:click="qCardCancelClick">
                          <div class="text-orange-9 work-title-text">
                            <q-checkbox
                              size="22px"
                              v-model="cancelFlag"
                              label=""
                              :disable="disableCancel||disabled||!editable"
                              @input="changeCancel" />
                              취소
                          </div>
                        </q-card-section>
                        <q-separator />
                        <q-card-section class="no-padding row">
                          <div class="col-12 work-item">
                            <c-textarea
                              :required="cancelFlag"
                              :editable="editable"
                              :disabled="disabled || !cancelFlag"
                              :rows="3"
                              label="취소사유"
                              beforeText=""
                              name="cancelRemark"
                              v-model="workPermit.cancelRemark" />
                          </div>
                        </q-card-section>
                      </q-card>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                      <q-card class="work-card" flat bordered
                        :class="overFlag ? 'bg-highLight' : ''">
                        <q-card-section
                          class="q-pa-xs q-pl-sm cursor-pointer bg-grey-3"
                          style="position: relative;">
                          <div style="position:absolute;right: 10px;top: 3px;z-index: 10600">
                            <q-btn-group outline >
                              <c-btn v-if="editable&&overFlag&&!disabled" label="추가" icon="add" :showLoading="false" @btnClicked="addOver" />
                              <c-btn v-if="editable&&overFlag&&!disabled" label="제외" icon="remove" :showLoading="false" @btnClicked="removeOver" />
                            </q-btn-group>
                          </div>
                        </q-card-section>
                        <q-card-section class="q-pa-xs q-pl-sm bg-grey-3"
                          style="min-height:23px;"
                          :class="this.editable&&!this.disabled ? 'cursor-pointer' : 'cursor-not-allowed'"
                          v-on:click="qCardOverClick">
                          <div class="text-orange-9 work-title-text float-left"
                            style="position:absolute;top: -3px;">
                            <q-checkbox
                              size="22px"
                              v-model="overFlag"
                              label=""
                              :disable="disabled"
                              @input="changeOver" />
                              연장
                          </div>
                        </q-card-section>
                        <q-separator />
                        <q-card-section class="no-padding row" style="width:100%">
                          <div class="col-12">
                            <c-table
                              class="work-permit-table"
                              ref="tableOver"
                              title=""
                              tableId="01"
                              :columns="grid.columns"
                              :data="workPermit.overs"
                              :gridHeight="grid.height"
                              :editable="editable&&overFlag&&!disabled"
                              :tableTimePickerOptions="tableTimePickerOptions"
                              :filtering="false"
                              :columnSetting="false"
                              :usePaging="false"
                              :isTitle="false"
                              :hideHeader="false"
                              :hideBottom="true"
                              :noHighLight="true"
                              :noHoverRow="true"
                              selection="multiple"
                              rowKey="sopOverResultId"
                            >
                            </c-table>
                          </div>
                        </q-card-section>
                      </q-card>
                    </div>
                    <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 grid-style-transition">
                      <q-card class="work-card" flat bordered
                        :class="completeFlag ? 'bg-highLight' : ''">
                        <q-card-section class="q-pa-xs q-pl-sm bg-grey-3"
                          :class="this.editable&&!this.disabled&&!this.disableComplete ? 'cursor-pointer' : 'cursor-not-allowed'"
                          v-on:click="qCardCompleteClick">
                          <div class="text-orange-9 work-title-text">
                            <q-checkbox
                              size="22px"
                              v-model="completeFlag"
                              label=""
                              :disable="disableComplete||disabled"
                              @input="changeComplete" />
                              완료
                          </div>
                        </q-card-section>
                        <q-separator />
                        <q-card-section class="no-padding row">
                          <div class="col-4 work-item">
                            <c-datepicker
                              :required="completeFlag"
                              :editable="editable"
                              :disabled="disabled||!completeFlag"
                              :time-picker-options="overTimeOption"
                              :minuteStep="10"
                              type="time"
                              label="완료시간"
                              beforeText=""
                              name="workCompleteTime"
                              v-model="workPermit.workCompleteTime"
                            />
                          </div>
                          <div class="col-4 work-item">
                            <c-field
                              :required="completeFlag"
                              :editable="editable"
                              :disabled="disabled||!completeFlag"
                              :data="workPermit"
                              deptValue="workCompleteEntryDeptCd"
                              type="dept_user"
                              label="확인자"
                              beforeText=""
                              name="workCompleteEntryUserId"
                              v-model="workPermit.workCompleteEntryUserId" />
                          </div>
                          <div class="col-4 work-item">
                            <c-field
                              :required="completeFlag"
                              :editable="editable"
                              :disabled="disabled||!completeFlag"
                              :data="workPermit"
                              deptValue="workCompleteWorkDeptCd"
                              type="dept_user"
                              label="작업자"
                              beforeText=""
                              name="workCompleteWorkUserId"
                              v-model="workPermit.workCompleteWorkUserId" />
                          </div>
                          <div class="col-12 work-item">
                            <c-text
                              :editable="editable"
                              :disabled="disabled||!completeFlag"
                              label="조치사항"
                              beforeText=""
                              name="actionRemark"
                              v-model="workPermit.actionRemark" />
                          </div>
                        </q-card-section>
                      </q-card>
                    </div> -->
                  </div>
                </q-slide-transition>
                <div class="col-12 folder-area">
                  <q-btn
                    style="width:100%"
                    size="2px"
                    color="light-blue"
                    :icon="folderIcon"
                    @click="folder" />
                </div>
              </template>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-tab
      class="q-mt-sm"
      type="tabcard"
      align="left"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      :dense="true"
      :height="cusTabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          ref="swp-tab"
          :is="tab.component"
          :key="tab.componentKey"
          :workPermit.sync="workPermit"
          :supWork.sync="tab.supWork"
          :gridLoto.sync="gridLoto"
          :isOld="isOld"
          :confiFlag="confiFlag"
          :isWriting="isWriting"
          :saveAction="saveAction"
          :height="cusTabHeight"
          @validResult="validResult"
        />
      </template>
      <template v-slot:customTab>
        <q-tab
          name="references"
          icon="description"
          label="관련정보">
        </q-tab>
      </template>
      <template v-slot:customTabPanel>
        <q-tab-panel
          name="references">
          <keep-alive>
            <component
              :is="referencesCompo"
              :key="referencesKey"
              :workPermit.sync="workPermit"
              :supWorks.sync="supWorks"
              :saveCall.sync="saveCall"
              :isWriting="isWriting"
              :height="tabHeight"
            />
          </keep-alive>
        </q-tab-panel>
      </template>
    </c-tab>
    <q-dialog v-model="returnDialog.show" persistent>
      <q-card style="min-width: 450px">
        <q-form ref="editForm2">
          <c-card title="반려 사유" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn 
                  label="닫기" 
                  icon="cancel" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-textarea
                  :editable="editable"
                  :readonly="true"
                  :rows="5"
                  label=""
                  name="returnRemark"
                  v-model="workPermit.returnRemark">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import eventBus from '@/js/eventBus';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import transactionPartnerConfig from '@/js/transactionPartnerConfig';
export default {
  name: 'safe-work-permit-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          sopWorkPermitId: '',
          permitTypeCd: '',
          isApprContent: false,
          approvalDocType: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      workPermit: {
        sopWorkPermitId: '',  // 작업허가서 일련번호
        plantCd: '',  // 사업장 코드
        sopName: '',  // 작업명
        mdmSopId: '',  // 안전작업 표준 일련번호_SOP에서 불러와서 관련 정보 표시후 수정
        permitNo: '',  // 허가번호_(YYYYMMDD-01)
        swpStepCd: '',  // 진행단계 코드
        applicationDeptCd: '',  // 신청인 부서코드
        applicationDeptName: '',
        applicationUserId: '',  // 신청인 ID
        applicationUserName: '',
        permitTypeCd: '',  // 허가서 구분_화기/일반
        permitDate: '',  // 허가일
        workStartTime: '', // 작업시작시간
        workEndTime: '', // 작업종료시간
        workTime: [], // 작업시간
        processCd: '',  // 작업공정
        workLocation: '',  // 작업장소
        locationEtc: '',  // 기타장소
        locationMarkFlag: 'Y',  // 위치 직접 표시 여부
        equipmentCd: '',  // 설비번호_설비마스터
        workSummary: '',  // 작업개요
        specialRequirements: '',  // 특별요구사항
        cancelRemark: '',  // 취소사유
        actionRemark: '',  // 조치사항
        maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
        maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
        maintenanceDeptSafetyManagerId: '',  // 정비부서 안전관리자 - 안전조치확인
        maintenanceDeptDrivingManagerId: '',  // 정비부서 운전부서장 - 안전조치확인
        workCompleteTime: '',  // 작업완료시간
        workCompleteEntryUserId: '',  // 작업완료 입회자/확인자
        workCompleteWorkUserId: '',  // 작업완료 작업자
        issuedDeptCd: '',  // 발급자 부서코드
        issuedUserId: '',  // 발급자 ID
        approvalDeptCd: '',  // 승인자 부서코드
        approvalUserId: '',  // 승인자 ID
        relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
        relationCooperation1UserId: '',  // 관련부서 협조자1 ID
        relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
        relationCooperation2UserId: '',  // 관련부서 협조자2 ID
        vendorCd: '',  // 작업업체 코드
        overWorkFlag: '',  // 연장작업 여부
        psmFlag: 'N',  // PSM 관련 여부
        sopMocId: '',  // MOC 일련번호
        assessWriteUserId: '',  // 작업위험성평가_작성자 ID
        assessApprovalUserId: '',  // 작업위험성평가_승인자 ID
        swpWorkRequestCd: '',  // 작업허가요청
        returnRemark: '', // 반려사유
        vendorFlag: 0, // 업체작성여부
        sopAssessmentCheckTypeCd1: '',  // 잠재위험요소1
        sopAssessmentCheckTypeCd2: '',  // 잠재위험요소2
        sopAssessmentCheckTypeCd3: '',  // 잠재위험요소3
        sopAssessmentCheckTypeCd4: '',  // 잠재위험요소4
        sopAssessmentCheckTypeCd5: '',  // 잠재위험요소5
        sopAssessmentCheckTypeCd6: '',  // 잠재위험요소6
        sopConstructionId: '',
        workerCnt: null,
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        supWorks: [],
        deleteSupWorks: [],
        checkResults: [],
        deleteCheckResults: [],
        gases: [], // 조회용
        gasChecks: [], // 저장용
        deleteGasChecks: [],
        deleteGasCheckVals: [],
        maps: [],
        deleteMaps: [],
        workers: [],
        deleteWorkers: [],
        assessments: [],
        deleteAssessments: [],
        overs: [],
        deleteOvers: [],
        protectiveGears: [],
        deleteProtectiveGears: [],
        lotos: [],
        deleteLotos: [],
        locationInfos: [],
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
      },
      supWorks: [],
      cusTabHeight: '',
      visible: false,
      folderIcon: 'expand_more',
      tab: '',
      tabItems: [
        // tab용 색상 class : tab-orange,tab-green,tab-red,tab-pink,tab-purple,tab-deeppurple,
        // tab-indigo,tab-blue,tab-lightblue,tab-cyan,tab-lightgreen,tab-deeporange,tab-brown,tab-grey,tab-bluegrey
        // { name: 'fire', icon: 'local_fire_department',  class:'tab-red', label: '화기', component: () => import(`${'./swpFire.vue'}`) },
      ],
      grid: {
        columns: [
          {
            name: 'overTime',
            field: 'overTime',
            label: '시간',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'datetime',
            minuteStep: 10,
          },
          {
            name: 'overReason',
            field: 'overReason',
            label: '사유',
            align: 'left',
            style: 'width:300px',
            sortable: true,
            type: 'text',
          },
        ],
        data: [],
        height: '110px'
      },
      gridLoto: {
        columns: [
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비관리번호', // 설비관리번호
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명', // 설비명
            align: 'left',
            style: 'width:150px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형', // 설비유형
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            required: true,
            name: 'sopLotoTypeCd',
            field: 'sopLotoTypeCd',
            label: '잠금장치', // 잠금장치
            align: 'center',
            style: 'width:150px',
            sortable: false,
            type: 'select',
            codeGroupCd: 'SOP_LOTO_TYPE_CD'
          },
          {
            name: 'equipmentLocation',
            field: 'equipmentLocation',
            label: '설비위치', // 설비위치
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
        ],
        height: '350px'
      },
      returnDialog: {
        show: false,
      },
      gasItems: [],
      protectiveGears: [],
      workTypes: [],
      checkResults: [],
      cancelFlag: false,
      overFlag: false,
      completeFlag: false,
      disableCancel: false,
      disableComplete: false,
      saveAction: '',
      editable: true,
      detailUrl: '',
      recentUrl: '',
      listGasItemUrl: '',
      listCheckItemUrl: '',
      saveUrl: '',
      deleteUrl: '',
      approvalUrl: '',
      overUrl: '',
      cancelUrl: '',
      completeUrl: '',
      requestUrl: '',
      rejectUrl: '',
      printUrl: '',
      isSave: false,
      isRequest: false,
      isReject: false,
      isApproval: false,
      mappingType: 'POST',
      saveCall: {
        saveCallData: '',
      },
      popupOptions: {
        isFull: true,
        isApproval: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let margin = 175;
      if (this.isWorkInfo) {
        margin += 30;
      }
      if (this.visible) {
        margin = Number(margin) + 160
      } else {
        margin = Number(margin)
      }
      return String(this.contentHeight - margin) + 'px';
    },
    overTimeOption() {
      let returnData = {
        start: '16:00', // this.$comm.moment(this.wkodMaster.workEndTime, 'HH:mm').add('10', 'm').format('HH:mm'),
        step: '00:10',
        end: '23:50'
      };
      if (this.workPermit.overs && this.workPermit.overs.length > 0) {
        returnData.start = this.workPermit.overs[this.workPermit.overs.length - 1].overTime
      }
      return returnData;
    },
    isOld() {
      return Boolean(this.popupParam.sopWorkPermitId)
    },
    referencesCompo() {
      return () => import(`${'./swpReferences.vue'}`);
    },
    referencesKey() {
      return uid();
    },
    disabled() {
      // 모든 입력폼이 비활성화 되는 상황
      // - 취소  - 작업완료
      // - 결재중
      return this.isOld 
            // - 취소  - 작업완료
        && (this.workPermit.swpStepCd === 'SS00000015' || this.workPermit.swpStepCd === 'SS00000020' 
          // 결재중인 경우 비활성화
          || this.workPermit.approvalStatusCd === 'ASC0000001'
          // 작업허가요청이 진행중인 경우
          || Boolean(this.workPermit.swpWorkRequestCd))
    },
    rejectDisabled() {
      // 모든 입력폼이 비활성화 되는 상황
      // - 취소  - 작업완료
      // - 결재중
      return this.isOld 
            // - 취소  - 작업완료
        && (this.workPermit.swpStepCd === 'SS00000015' || this.workPermit.swpStepCd === 'SS00000020' 
          // 결재중인 경우 비활성화
          || this.workPermit.approvalStatusCd === 'ASC0000001'
          // 작업허가요청이 진행중인 경우
          || !this.workPermit.swpWorkRequestCd)
    },
    isWorking() {
      // 작업 승인이 났으며 작업이 진행중인 상황
      // - 승인완료  - 연장
      return this.isOld && (this.workPermit.swpStepCd === 'SS00000005' || this.workPermit.swpStepCd === 'SS00000010'
      || this.workPermit.swpStepCd === 'SS00000020' )
    },
    isWorkInfo() {
      // 작업 승인이 났으며 작업이 진행중인 상황
      // - 승인완료  - 연장
      return this.isOld && (this.workPermit.swpStepCd !== 'SS00000001')
    },
    isWriting() {
      // 허가서 작성중인 상황
      // - 작성중 - 결재중이 아닌경우
      // 작업허가요청이 진행중이 아닌 경우
      return (!this.isOld || this.workPermit.swpStepCd === 'SS00000001') 
        && this.workPermit.approvalStatusCd !== 'ASC0000001'
        && !this.workPermit.swpWorkRequestCd
    },
    saveBtnLabel() {
      return !this.isOld || this.workPermit.swpStepCd === 'SS00000001' ? '일괄저장' : '취소 - 연장 처리'
    },
    requestBtnLabel() {
      return !this.isOld || this.workPermit.swpStepCd === 'SS00000001' ? '승인요청' : '취소 - 연장 요청'
    },
    tableTimePickerOptions() {
      let returnData = {
        colName: 'overTime',
        start: '16:00',
        step: '00:10',
        end: '23:50'
      };
      if (this.workPermit.workEndTime) {
        returnData.start = this.workPermit.workEndTime
        returnData.end = this.$comm.moment(this.$comm.getToday() + ' ' + this.workPermit.workEndTime).add(2, 'h').format('HH:mm')
      }
      return returnData;
    },
    // approvalInfo() {
    //   return {
    //     sysApprovalRequestId: this.workPermit.sysApprovalRequestId, // 결재요청번호
    //     /**
    //      * 결재상태코드
    //      * ASC0000001	결재중
    //      * ASC0000002	반려
    //      * ASC9999999	결재완료
    //      */
    //     approvalStatusCd: this.workPermit.approvalStatusCd, 
    //     apprEditable: this.isOld && this.workPermit.swpStepCd === 'SS00000001', // 결재버튼 활성화 기준
    //     param: this.workPermit, // 결재 param
    //     approvalUrl: this.approvalUrl, // 결재 url
    //     isApproval: this.isApproval, // 결재 submit
    //     approvalTypeCd: 'APTC000001', // 결재유형코드
    //     approvalParamValue: { // 결재상세화면 파라미터
    //       sopWorkPermitId: this.workPermit.sopWorkPermitId,
    //       permitTypeCd: this.workPermit.permitTypeCd,
    //       isApprContent: true
    //     },
    //     approvalRequestName: this.workPermit.workSummary, // 결재요청명 (문서 title)
    //     approvalConnId: this.workPermit.sopWorkPermitId, // 결재연결용 업무일련번호 (문서 key)
    //   }
    // },
    // [E] 결재관련 버튼 컨트롤
    workRequestDescription() {
      let text = '';
      if (this.workPermit.swpWorkRequestCd === 'SWR0000001') {
        text = `담당자가 현재 작업허가 요청 검토중입니다.`
      } else if (this.workPermit.swpWorkRequestCd === 'SWR0000005') {
        text = `담당자가 현재 작업허가 연장 검토중입니다.`
      } else if (this.workPermit.swpWorkRequestCd === 'SWR0000010') {
        text = `담당자가 현재 작업허가 취소 검토중입니다.`
      } else if (this.workPermit.swpWorkRequestCd === 'SWR0000015') {
        text = `담당자가 현재 작업허가 완료 검토중입니다.`
      }
      return text;
    },
    confiFlag() {
      return this.$_.indexOf(this.supWorks, 'SSWT000001') > -1
    },
  },
  watch: {
    tabHeight() {
      let bol = window.innerWidth <= 768;
      this.cusTabHeight = (!bol ? this.tabHeight : 'auto');
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.swp.workPermit.get.url
      this.recentUrl = selectConfig.sop.swp.workPermit.recent.url
      this.listGasItemUrl = selectConfig.sop.swp.gas.list.url
      this.listCheckItemUrl = selectConfig.sop.swp.workChekItem.list.url
      this.saveUrl = transactionConfig.sop.swp.workPermit.insert.url
      this.deleteUrl = transactionConfig.sop.swp.workPermit.delete.url
      this.approvalUrl = transactionConfig.sop.swp.workPermit.approval.url
      this.approvalReqUrl = transactionConfig.sop.swp.workPermit.approval.url + '/apprrequest'
      this.overUrl = transactionConfig.sop.swp.workPermit.over.url
      this.cancelUrl = transactionConfig.sop.swp.workPermit.cancel.url
      this.completeUrl = transactionConfig.sop.swp.workPermit.complete.url
      this.requestUrl = transactionPartnerConfig.sop.swp.workPermit.request.url
      this.rejectUrl = transactionPartnerConfig.sop.swp.workPermit.reject.url
      this.printUrl = selectConfig.sop.swp.workPermit.print.url
      // code setting
      // 보호구
      this.$comm.getMultiComboItems(['SOP_PROTECTIVE_GEAR_CD', 'SOP_WORK_TYPE_CD']).then(_result => {
        this.protectiveGears = _result['SOP_PROTECTIVE_GEAR_CD']
        this.workTypes = _result['SOP_WORK_TYPE_CD']
        if (!this.isOld) {
          this.setProtectiveGear()
        }
      });
      // list setting
      this.getDetail();
    },
    getDetail(flag) {
      if (!this.tabItems) {
        this.tabItems = [];
      }
      this.tab = this.popupParam.permitTypeCd === 'SPT0000001' ? 'normal' : 'fire';
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopWorkPermitId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.workPermit, _result.data);
          // this.workPermit.workTime = this.workPermit.workStartTime && this.workPermit.workEndTime ?
          //   [this.workPermit.workStartTime, this.workPermit.workEndTime] : []
          this.supWorks = this.$_.map(this.workPermit.supWorks, 'supplementWorkTypeCd')

          this.overFlag = this.workPermit.overs && this.workPermit.overs.length > 0
          this.cancelFlag = this.workPermit.swpStepCd === 'SS00000015' || this.workPermit.swpWorkRequestCd === 'SWR0000010'
          this.completeFlag = this.workPermit.swpStepCd === 'SS00000020' || this.workPermit.swpWorkRequestCd === 'SWR0000015'
          if (flag === 'save') {
            this.saveCall.saveCallData = uid();
          }
          this.setTabItems(flag);

          // 취소/연장/작업완료인 경우 
          const stepVisible = [
            'SS00000005',
            'SS00000010',
            'SS00000015',
            'SS00000020'
          ]
          if (stepVisible.indexOf(this.workPermit.swpStepCd) > -1 && !this.visible) {
            this.folder();
          }
          this.getCheckItemList();
        },);
      } else {
        // 작성한 작업허가서 정보가 없는 경우 default setting
        this.workPermit.permitTypeCd = this.popupParam.permitTypeCd
        this.workPermit.vendorCd = this.$store.getters.user.deptCd
        this.workPermit.applicationDeptCd = this.$store.getters.user.deptCd
        this.workPermit.applicationDeptName = this.$store.getters.user.deptName
        this.workPermit.applicationUserId = this.$store.getters.user.userId
        this.workPermit.applicationUserName = this.$store.getters.user.userName
        this.workPermit.issuedDeptCd = this.$store.getters.user.deptCd
        this.workPermit.issuedUserId = this.$store.getters.user.userId
        this.workPermit.permitDate = this.$comm.getToday();
        this.workPermit.workStartTime = '08:00'
        this.workPermit.workEndTime = '16:00';
        this.setTabItems(flag);
        this.getCheckItemList();

        // this.$http.url = this.recentUrl;
        // this.$http.type = 'GET';
        // this.$http.param = {
        //   permitTypeCd: this.popupParam.permitTypeCd,
        //   regUserId: this.$store.getters.user.userId
        // };
        // this.$http.request((_result) => {
        //   if (_result.data) {
        //     /**
        //      * 자신이 작성한 최신 작업허가서 정보 setting
        //      * 
        //      * 셋팅 항목
        //      *  1. 허가서 (허가일, 허가작업일, 작업개요, MOC, 연장/취소/완료정보는 가지고 오지 않음)
        //      *  2. 안전조치 요구사항 (허가서)
        //      *  3. 가스농도 (허가서)
        //      *  4. 보호구
        //      */
        //     this.$_.extend(this.workPermit, _result.data);
        //     this.workPermit.workTime = this.workPermit.workStartTime && this.workPermit.workEndTime ?
        //       [this.workPermit.workStartTime, this.workPermit.workEndTime] : []
        //   } else {
        //     // 작성한 작업허가서 정보가 없는 경우 default setting
        //     this.workPermit.permitTypeCd = this.popupParam.permitTypeCd
        //     this.workPermit.applicationDeptCd = this.$store.getters.user.deptCd
        //     this.workPermit.applicationDeptName = this.$store.getters.user.deptName
        //     this.workPermit.applicationUserId = this.$store.getters.user.userId
        //     this.workPermit.applicationUserName = this.$store.getters.user.userName
        //     this.workPermit.vendorCd = this.$store.getters.user.userId
        //     this.workPermit.swpWorkClassCd = 'SWC0000001' // 외주작업
        //     this.workPermit.permitDate = this.$comm.getToday();
        //     this.workPermit.workTime = ['08:00', '16:00'];
        //   }
        //   this.setTabItems(flag);
        // },);
      }
    },
    getCheckItemList() {
      this.$http.url = this.listCheckItemUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.workPermit.plantCd,
        useFlag: 'Y',
      }
      this.$http.request((_result) => {
        this.checkResults = this.$_.map(_result.data, item => {
          let supplementWorkTypeCd = '';
          switch(item.sopWorkTypeCd) {
            case 'SWT0000010':
              supplementWorkTypeCd = 'SSWT000001';
              break;
            case 'SWT0000015':
              supplementWorkTypeCd = 'SSWT000005';
              break;
            case 'SWT0000020':
              supplementWorkTypeCd = 'SSWT000010';
              break;
            case 'SWT0000025':
              supplementWorkTypeCd = 'SSWT000015';
              break;
            case 'SWT0000030':
              supplementWorkTypeCd = 'SSWT000020';
              break;
            case 'SWT0000035':
              supplementWorkTypeCd = 'SSWT000025';
              break;
          }
          
          return {
            sopWorkPermitId: '',  // 작업허가서 일련번호
            supplementWorkTypeCd: supplementWorkTypeCd,  // 보충작업구분
            sopCheckItemId: item.sopCheckItemId,  // 점검항목 일련번호
            checkItemName: item.checkItemName, // 점검항목명
            sopCheckItemKindCd: item.sopCheckItemKindCd, // 체크항목 종류
            necessaryFlag: 'Y',  // 필요
            // confirmFlag: 'N',  // 확인
            confirmUserId: '',  // 확인자
            confirmUserName: '',  // 확인자명
            regUserId: this.$store.getters.user.userId,  // 등록자 ID
            chgUserId: '',  // 수정자 ID
            editFlag: 'C'
          }
        })
      },);
    },
    setTabItems(flag) {
      // this.tabItems = [];
      if (this.popupParam.permitTypeCd === 'SPT0000001') {
        if (this.checkTab('normal')) {
          this.tabItems.push({ componentKey: uid(),name: 'normal', icon: 'engineering',  class:'tab-lightblue', label: '일반', component: () => import(`${'./swpNormal.vue'}`) })
        }
      } else {
        if (this.checkTab('fire')) {
          this.tabItems.push({ componentKey: uid(),name: 'fire', icon: 'local_fire_department',  class:'tab-red', label: '화기', component: () => import(`${'./swpFire.vue'}`) })
        }
      }
      if (this.workPermit.supWorks && this.workPermit.supWorks.length > 0) {
        this.$_.forEach(this.workPermit.supWorks, supWork => {
          let checkIdx = this.$_.findIndex(this.tabItems, { supplementWorkTypeCd: supWork.supplementWorkTypeCd })
          if (checkIdx === -1) {
            let pushData = {}
            switch (supWork.supplementWorkTypeCd) {
              case 'SSWT000001':
                pushData = {
                  componentKey: uid(),
                  name: supWork.supplementWorkTypeCd, class: 'tab-bluegrey', icon: 'dangerous', label: '밀폐공간', component: () => import(`${'./swpConfinedSpace.vue'}`),
                  supplementWorkTypeCd: supWork.supplementWorkTypeCd, supWork: supWork
                }
                break;
              case 'SSWT000005':
                pushData = {
                  componentKey: uid(),
                  name: supWork.supplementWorkTypeCd, class: 'tab-bluegrey', icon: 'flash_off', label: '정전', component: () => import(`${'./swpBlackout.vue'}`),
                  supplementWorkTypeCd: supWork.supplementWorkTypeCd, supWork: supWork
                }
                break;
              // case 'SSWT000010':
              //   pushData = {
              //     componentKey: uid(),
              //     name: supWork.supplementWorkTypeCd, class: 'tab-bluegrey', icon: 'warning', label: '방사선', component: () => import(`${'./swpRadiation.vue'}`),
              //     supplementWorkTypeCd: supWork.supplementWorkTypeCd, supWork: supWork
              //   }
              //   break;
              case 'SSWT000015':
                pushData = {
                  componentKey: uid(),
                  name: supWork.supplementWorkTypeCd, class: 'tab-bluegrey', icon: 'hardware', label: '굴착', component: () => import(`${'./swpExcavation.vue'}`),
                  supplementWorkTypeCd: supWork.supplementWorkTypeCd, supWork: supWork
                }
                break;
              case 'SSWT000020':
                pushData = {
                  componentKey: uid(),
                  name: supWork.supplementWorkTypeCd, class: 'tab-bluegrey', icon: 'stairs', label: '고소', component: () => import(`${'./swpHighPlace.vue'}`),
                  supplementWorkTypeCd: supWork.supplementWorkTypeCd, supWork: supWork
                }
                break;
              // case 'SSWT000025':
              //   pushData = {
              //     componentKey: uid(),
              //     name: supWork.supplementWorkTypeCd, class: 'tab-bluegrey', icon: 'agriculture', label: '중장비', component: () => import(`${'./swpHeavyEquipment.vue'}`),
              //     supplementWorkTypeCd: supWork.supplementWorkTypeCd, supWork: supWork
              //   }
              //   break;
              default:
              // code block
            }
            if (this.checkTab(supWork.supplementWorkTypeCd)) {
              this.tabItems.push(pushData)
            }
          } else {
            this.$set(this.tabItems[checkIdx], 'supWork', supWork)
          }
        })
        let deleteItems = [];
        this.$_.forEach(this.tabItems, item => {
          if (this.$_.findIndex(this.workPermit.supWorks, (supWork) => {
            return supWork.supplementWorkTypeCd === item.supplementWorkTypeCd
          }) === -1 && (item.name !== 'normal' && item.name !== 'fire')) {
            deleteItems.push(item)
          }
        })
        if (deleteItems && deleteItems.length > 0) {
          this.$_.forEach(deleteItems, delItem => {
            this.tabItems = this.$_.reject(this.tabItems, delItem);
          });
        }
      } else {
        /**
         * 허가서를 제외한 나머지 보충작업 삭제
         */
        this.tabItems = this.$_.filter(this.tabItems, (item) => {
          return item.name === 'normal' || item.name === 'fire'
        })
      }

      if (this.tabItems && this.tabItems.length > 0) {
        let deleteItems = [];
        this.$_.forEach(this.tabItems, item => {
          if (this.$_.findIndex(this.workPermit.supWorks, { supplementWorkTypeCd: item.supplementWorkTypeCd }) === -1) {
            deleteItems.push(item)
          }
        });

        this.$_.forEach(deleteItems, item => {
          this.$set(this.tabItems, item, null)
        });
      }

      // 저장하고 난 후 조치
      if (flag === 'save') {
        this.saveAction = uid();
      }
    },
    checkTab(tab) {
      return this.$_.findIndex(this.tabItems, { name: tab }) === -1
    },
    /* eslint-disable no-unused-vars */
    changeSupWork(value) {
      /**
        보충작업이 입력되었을 경우

         - this.workPermit.supWorks에 영향을 준다.
         - this.setTabItems() method를 호출한다.
       */
      // ## 추가된 보충작업을 넣는 과정
      if (this.supWorks && this.supWorks.length > 0) {
        this.$_.forEach(this.supWorks, supWork => {
          if (this.$_.findIndex(this.workPermit.supWorks, { supplementWorkTypeCd: supWork }) === -1) {
            this.workPermit.supWorks.push({
              sopWorkPermitId: this.popupParam.sopWorkPermitId ? this.popupParam.sopWorkPermitId : uid(),  // 작업허가서 일련번호
              supplementWorkTypeCd: supWork,  // 보충작업구분
              workProposalNo: '',  // 작업 신청번호_방사선, 고소, 중장비
              workLocation: '',  // 작업장소_방사선, 고소, 중장비
              workSummary: '',  // 작업개요_고소, 방사선,굴착, 중장비
              issuedDeptCd: '',  // 발급자 부서코드
              issuedUserId: '',  // 발급자 ID
              approvalDeptCd: '',  // 승인자 부서코드
              approvalUserId: '',  // 승인자 ID
              maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
              maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
              relationCooperation1DeptCd: '',  // 관려부서 협조자1 부서코드
              relationCooperation1UserId: '',  // 관련부서 협조자1 ID
              relationCooperation2DeptCd: '',  // 관려부서 협조자2 부서코드
              relationCooperation2UserId: '',  // 관련부서 협조자2 ID
              specialRequirements: '',  // 특별요구사항
              closeMachine: '',  // (밀폐)장치명
              closeEntryResaon: '',  // (밀폐)출입사유
              closeEntryUserIds: '',  // (밀폐)출입자id_구분자이용 복수등록
              closeEntryUserNames: '',  // (밀폐)출입자명_구분자이용 복수등록
              entrants: [], // 입출입자
              deleteEntrants: [],
              closeExpectedRisk: '',  // (밀폐)예상위험
              closeGuardianId: '',  // (밀폐)감시인
              closeGuardianMobileNo: '',  // (밀폐)감시인 전화번호
              blackoutCircuitBreaker: '',  // (정전)차단기기
              blackoutCircuitBreakerNo: '',  // (정전)차단기 번호
              blackoutPowerRecoveryRequestUserId: '',  // (정전)전원복구 요청자
              blackoutPowerRecoveryTime: '',  // (정전)전원복구 시간
              blackoutElectricBlockUserId: '',  // (정전)차단확인자(전기)
              blackoutMaintenanceBlockUserId: '',  // (정전)차단확인자(정비)
              radiationResponsibleUserId: '',  // (방사선)방사능 책임자
              heavyEquipSafetyCheckUserId: '',  // (중장비)안전조치 확인자
              heavyEquipManageUserId: '',  // (중장비)장비관리 책임자
              heavyEquipDriveUserId: '',  // (중장비)운전원
              heavySignalMan: '',  // (중장비) 신호수
              heavyType: '',  // (중장비) 중장비 종류
              heavyEquipDirectorRequirements: '',  // (중장비) 감독 요구사항
              heavyEquipRequireDeptRequirements: '',  // (중장비)요청부서 요구사항
              heavyEquipSupportDeptRequirements: '',  // (중장비) 지원부서 요구사항
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              chgUserId: '',  // 수정자 ID
              checkResults: this.checkResults && this.checkResults.length > 0 ? this.$_.filter(this.checkResults, { supplementWorkTypeCd: supWork }) : [], // 안전조치항목
              gases: [], // 가스항목측정 ** 사용하지 않는 보충작업도 존재하지만 빈 배열을 할당시켜 관리
              gasChecks: [], // 저장용
              deleteGasChecks: [],
              deleteGasCheckVals: [],
              editFlag: 'C',
              sketchSrc: '',
            })
          }
        })
      }

      // ## 삭제된 보충작업을 빼는 과정
      if (this.workPermit.supWorks && this.workPermit.supWorks.length > 0) {
        let deleteItems = [];
        this.$_.forEach(this.workPermit.supWorks, item => {
          if (this.$_.indexOf(this.supWorks, item.supplementWorkTypeCd) === -1) {
            deleteItems.push(item)
          }
        })

        if (!this.workPermit.deleteSupWorks) this.workPermit.deleteSupWorks = [];
        this.$_.forEach(deleteItems, deleteItem => {
          if (this.$_.findIndex(this.workPermit.deleteSupWorks, { supplementWorkTypeCd: deleteItem.supplementWorkTypeCd }) === -1
            && deleteItem.editFlag !== 'C') {
            this.workPermit.deleteSupWorks.push(deleteItem)
          }
          let idx = this.$_.findIndex(this.workPermit.supWorks, { supplementWorkTypeCd: deleteItem.supplementWorkTypeCd });
          this.workPermit.supWorks.splice(idx, 1);
        })
      }
      this.setTabItems();
      this.setMapResultTypes();
      this.setProtectiveGear()
    },
    setMapResultTypes() {
      if (this.workPermit.locationMarkFlag === 'N' && this.workPermit.maps && this.workPermit.maps.length > 0) {
        let resultTypes = [{ sopWorkTypeCd: this.workPermit.permitTypeCd}];
        if (this.workPermit.supWorks && this.workPermit.supWorks.length > 0) {
          this.$_.forEach(this.workPermit.supWorks, sup => {
            resultTypes.push({
              sopWorkTypeCd: sup.supplementWorkTypeCd
            })
          })
        }

        this.$_.forEach(this.workPermit.maps, map => {
          this.$set(map, 'resultTypes', resultTypes)
        })
      }
    },
    getWorkType(permitType) {
      let workType = '';
      if (this.workTypes && this.workTypes.length > 0) {
        let data = this.$_.find(this.workTypes, { attrVal2: permitType });
        workType = data ? data.code : '';
      }
      return workType
    },
    setProtectiveGear() {
      /**
       * 허가서 종류 popupParam.permitTypeCd
       * 보충작업들 supWorks
       */
      let gears = [];
      let workTypes = [this.getWorkType(this.popupParam.permitTypeCd)]
      if (this.supWorks && this.supWorks.length > 0) {
        this.$_.forEach(this.supWorks, supWork => {
          workTypes.push(this.getWorkType(supWork))
        })
      }

      this.$_.forEach(workTypes, workType => {
        gears = this.$_.concat(gears, this.$_.filter(this.protectiveGears, gear => {
          return gear.attrVal1 ? gear.attrVal1.indexOf(workType) > -1 : -1
        }))
      })
      this.$set(this.workPermit, 'protectiveGears', this.$_.uniqBy(this.$_.map(gears, gear => {
        return {
          sopProtectiveGearCd: gear.code
        }
      }), 'sopProtectiveGearCd'))
    },
    qCardCancelClick() {
      if (this.editable&&!this.disabled&&!this.disableCancel) {
        this.cancelFlag = !this.cancelFlag
        this.changeCancel(this.cancelFlag)
      }
    },
    changeCancel(val) {
      if (val) {
        this.disableComplete = true;
      } else {
        this.disableComplete = false;
        this.workPermit.cancelRemark = '';
      }
    },
    qCardOverClick() {
      if (this.editable&&!this.disabled) {
        this.overFlag = !this.overFlag
        this.changeOver(this.overFlag)
      }
    },
    changeOver(val) {
      if (!val) {
        if (this.workPermit.overs && this.workPermit.overs.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '연장을 해제하시면 연장정보가 사라집니다. 진행하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              if (!this.workPermit.deleteOvers) this.workPermit.deleteOvers = [];
              this.$_.forEach(this.workPermit.overs, over => {
                if (this.$_.findIndex(this.workPermit.deleteOvers, { sopOverResultId: over.sopOverResultId }) === -1) {
                  this.workPermit.deleteOvers.push(this.$_.clone(over))
                }
              });
              this.$set(this.workPermit, 'overs', [])
            },
            // 취소 callback 함수
            cancelCallback: () => {
              this.overFlag = true;
            },
          });
        }
      }
    },
    qCardCompleteClick() {
      if (this.editable&&!this.disabled&&!this.disableComplete) {
        this.completeFlag = !this.completeFlag
        this.changeComplete(this.completeFlag)
      }
    },
    changeComplete(val) {
      if (val) {
        this.disableCancel = true;
      } else {
        this.disableCancel = false;
        this.workPermit.actionRemark = '';
        this.workPermit.workCompleteEntryUserId = '';
        this.workPermit.workCompleteWorkUserId = '';
        this.workPermit.workCompleteTime = '';
      }
    },
    addOver() {
      this.workPermit.overs.push({
        sopOverResultId: uid(),  // 작업허가서 연장정보 번호
        sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
        overTime: '',  // 연장시간
        overReason: '',  // 연장사유
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      });
    },
    removeOver() {
      let selectData = this.$refs['tableOver'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '제외할 행을 지정하세요.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workPermit.deleteOvers) {
          this.workPermit.deleteOvers = [];
        }
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workPermit.deleteOvers, { sopOverResultId: item.sopOverResultId }) === -1) {
            this.workPermit.deleteOvers.push(item)
          }
          this.workPermit.overs = this.$_.reject(this.workPermit.overs, item);
        });
        this.$refs['tableOver'].$refs['compo-table'].clearSelection();
      }
    },
    folder() {
      this.visible = !this.visible
      if (this.visible) {
        this.folderIcon = 'expand_less'
      } else {
        this.folderIcon = 'expand_more'
      }
    },
    loadWorkPermit() {
      this.popupOptions.title = "작업허가서 선택"; // 작업허가서 선택
      this.popupOptions.param = {
        type: "single",
        permitTypeCd: this.popupParam.permitTypeCd,
        regUserId: this.$store.getters.user.userId
      };
      this.popupOptions.target = () => import(`${"@/pages/sop/swp/safeWorkPermitPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeWorkPermitPopup;
    },
    closeWorkPermitPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$http.url = this.$format(this.detailUrl, data[0].sopWorkPermitId);
        this.$http.type = 'GET';
        this.$http.param = {
          load: 'true'
        };
        this.$http.request((_result) => {
          this.$_.extend(this.workPermit, _result.data);
          // this.workPermit.workTime = this.workPermit.workStartTime && this.workPermit.workEndTime ?
          //   [this.workPermit.workStartTime, this.workPermit.workEndTime] : []
          this.supWorks = this.$_.map(this.workPermit.supWorks, 'supplementWorkTypeCd')
          this.setTabItems();
        },);
      }
    },
    disabledWorkStartTime(date) {
      return this.workPermit.workEndTime ? 
        date < this.$comm.moment(this.$comm.getToday() + ' ' + this.workPermit.workEndTime).add(-8, 'h').toDate() : false;
    },
    changeStartTime() {
      /**
       * 8시간 간격은 자동으로 됨으로 체크 X
       * 시작시간이 종료시간보다 큰 경우 체크
       */
      if (this.workPermit.workStartTime && this.workPermit.workEndTime) {
        let start = this.$comm.moment(this.$comm.getToday() + ' ' + this.workPermit.workStartTime).toDate()
        let end = this.$comm.moment(this.$comm.getToday() + ' ' + this.workPermit.workEndTime).toDate()
        this.$set(this.workPermit, 'workEndTime', start > end ? this.workPermit.workStartTime : this.workPermit.workEndTime)
      }
    },
    disabledWorkEndTime(date) {
      return this.workPermit.workStartTime ? 
        date > this.$comm.moment(this.$comm.getToday() + ' ' + this.workPermit.workStartTime).add(8, 'h').toDate() : false;
    },
    requestWorkPermit() {
      if (this.isOld && this.workPermit.swpStepCd !== 'SS00000001') {
        // 승인완료 된 경우 취소/연장/완료 중 데이터가 들어간 경우에만 작동하도록 처리
        if (!this.cancelFlag && !this.overFlag && !this.completeFlag) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '취소/연장/완료 데이터를 넣은 후 이용바랍니다.', // 취소/연장/완료 데이터를 넣은 후 이용바랍니다.
            type: 'warning', // success / info / warning / error
          });
          return;
        }
      }
      if (this.workPermit.isClosedSpaceEquip && this.workPermit.permitTypeCd === 'SPT0000001') {
        /**
         * 일반작업허가서에서 관련설비가 밀폐공간 정보를 가지고 있을 시 저장 X
         */
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '해당 설비는 밀폐작업허가서에서 적용 가능합니다.', // 해당 설비는 밀폐작업허가서에서 적용 가능합니다.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      /**
       * 작업허가서 지도 
       */
      let check = true;
      if (this.workPermit.maps && this.workPermit.maps.length > 0) {
        this.$_.forEach(this.workPermit.maps, map => {
          check = map.resultTypes && map.resultTypes.length > 0
          if (!check) {
            return false;
          }
        })
      }
      if (!check) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '지도에 표시한 위치에 보충작업이 선정되지 않았습니다.', // 해당 설비는 밀폐작업허가서에서 적용 가능합니다.
          type: 'warning', // success / info / warning / error
        });
        return;
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          eventBus.$emit('vaildForm', 'request')
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    rejectWorkPermit() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          eventBus.$emit('vaildForm', 'reject')
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveWorkPermit() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.swp.workPermit.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.swp.workPermit.insert.url
        this.mappingType = 'POST';
        this.workPermit.swpStepCd = 'SS00000001' // 작성중
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          eventBus.$emit('vaildForm', 'save')
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    validResult(_result, type) {
      if (_result && this.$comm.validTable(this.gridLoto.columns, this.workPermit.lotos, null, true)) {
        if (type === 'save' || type === 'request' || type === 'reject') {
          this.workPermit.swpWorkRequestType = 'appr' // 승인
          let message = type === 'save' ? '저장하시겠습니까?' : '요청하시겠습니까?';
          /**
           * 1. 작업승인을 하지 않은 경우 일반 저장 처리
           * 2. 취소를 체크한 경우 작업완료 체크여부 확인
           *                      취소사유 기입 되었는지 확인
           * 3. 연장만 체크한 경우 연장 정보가 들어갔는지 확인
           * 4. 완료를 체크한 경우 취소 체크여부 확인
           */
          if (this.overFlag) {
            // 연장을 체크한 경우
            if (!this.workPermit.overs || this.workPermit.overs.length === 0) {
              window.getApp.$emit('ALERT', {
                title: '안내', 
                message: '연장된 정보를 하나 이상 입력하십시오.', 
                type: 'warning', // success / info / warning / error
              });
              return;
            }
            this.saveUrl = transactionConfig.sop.swp.workPermit.over.url
            this.mappingType = 'PUT';
            message = type === 'save' ? '작업 연장하시겠습니까?' : '작업 연장 요청하시겠습니까?'
            this.workPermit.swpWorkRequestType = 'over' // 연장
          }
          if (this.cancelFlag) {
            // 취소가 체크된 경우
            if (this.completeFlag) {
              window.getApp.$emit('ALERT', {
                title: '안내', 
                message: '완료와 취소는 동시에 진행 할 수 없는 상태입니다.\n\r하나를 선택 하제 바랍니다.', 
                type: 'warning', // success / info / warning / error
              });
              return;
            } else if (!this.workPermit.cancelRemark) {
              window.getApp.$emit('ALERT', {
                title: '안내', 
                message: '취소사유를 입력하시기 바랍니다.', 
                type: 'warning', // success / info / warning / error
              });
              return;
            }
            this.saveUrl = transactionConfig.sop.swp.workPermit.cancel.url
            this.mappingType = 'PUT';
            message = type === 'save' ? '작업 취소하시겠습니까?' : '작업 취소 요청하시겠습니까?'
            this.workPermit.swpWorkRequestType = 'cancel' // 취소
          }
          if (this.completeFlag) {
            // 완료를 체크한 경우
            if (this.cancelFlag) {
              window.getApp.$emit('ALERT', {
                title: '안내', 
                message: '완료와 취소는 동시에 진행 할 수 없는 상태입니다.\n\r하나를 선택 하제 바랍니다.', 
                type: 'warning', // success / info / warning / error
              });
              return;
            }
            this.saveUrl = transactionConfig.sop.swp.workPermit.complete.url
            this.mappingType = 'PUT';
            message = type === 'save' ? '작업 완료하시겠습니까?' : '작업 완료 요청하시겠습니까?'
            this.workPermit.swpWorkRequestType = 'complete' // 완료
          }

          /**
           * 유효성체크
           *
           * 각 탭 별 유효성 체크 필요 ** 해당 부분 만들어야 함
           */
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: message,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let promises = [
                {
                  func: this.getGasItems,
                },
                {
                  func: this.setSaveValue,
                },
                {
                  func: type === 'save' ? this.saveValue : type === 'request' ? this.requestValue : this.rejectValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          /**
           * 유효성체크
           *
           * 각 탭 별 유효성 체크 필요 ** 해당 부분 만들어야 함
           */
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '결재요청 하기 전 입력된 값을 저장합니다. 진행하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let promises = [
                {
                  func: this.getGasItems,
                },
                {
                  func: this.setSaveValue,
                },
                {
                  func: this.approvalValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });

        }
      } else {
        window.getApp.$emit('APP_VALID_ERROR');
      }
    },
    getGasItems() {
      return new Promise(resolve => {
        this.$http.url = this.listGasItemUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.workPermit.plantCd,
        }
        this.$http.request((_result) => {
          this.gasItems = _result.data
          resolve(true);
        }, (_error) => {
          resolve(false);
        });
      });
    },
    setSaveValue() {
      return new Promise(resolve => {
        this.workPermit.regUserId = this.$store.getters.user.userId
        this.workPermit.chgUserId = this.$store.getters.user.userId

        // this.workPermit.workStartTime = this.workPermit.workTime[0]
        // this.workPermit.workEndTime = this.workPermit.workTime[1]

        this.setGasData('permit')
        this.setGasData('supwork')
        resolve(true);
      });
    },
    setGasData(target) {
      let targetArray = target === 'permit' ? [this.workPermit] : this.workPermit.supWorks
      if (targetArray && targetArray.length > 0) {
        this.$_.forEach(targetArray, targetObj => {

          if (targetObj.gases && targetObj.gases.length > 0) {
            this.$_.forEach(targetObj.gases, gas => {
              let gasValues = [];
              if (this.gasItems && this.gasItems.length > 0) {
                this.$_.forEach(this.gasItems, gasItem => {
                  if (gas.hasOwnProperty(gasItem.sopGasId)) {
                    gasValues.push({
                      sopGasCheckId: gas.sopGasCheckId,  // 가스농도측정번호
                      sopGasId: gasItem.sopGasId,  // 가스번호
                      measurement: gas[gasItem.sopGasId],  // 측정치
                      regUserId: gas.regUserId,  // 등록자 ID
                      chgUserId: gas.chgUserId,  // 수정자 ID
                      editFlag: gas.editFlag
                    })
                  }
                })
              }
              if (!targetObj.gasChecks) targetObj.gasChecks = [];
              targetObj.gasChecks.push({
                sopGasCheckId: gas.sopGasCheckId,  // 가스농도측정번호
                sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
                supplementWorkTypeCd: target === 'permit' ? '' : targetObj.supplementWorkTypeCd,  // 보충작업구분
                checkTime: gas.checkTime,  // 점검시간
                gasCheckMachine: gas.gasCheckMachine,  // 점검기기명
                gasCheckUserId: gas.gasCheckUserId,  // 점검자
                gasConfirmUserId: gas.gasConfirmUserId,  // 확인자(입회자)
                regUserId: gas.regUserId,  // 등록자 ID
                chgUserId: gas.chgUserId,  // 수정자 ID
                editFlag: gas.editFlag,
                gasValues: gasValues
              })

            });
          }

        })
      }
    },
    saveValue() {
      return new Promise(resolve => {
        this.isSave = !this.isSave
        resolve(true);
      });
    },
    requestValue() {
      return new Promise(resolve => {
        this.isRequest = !this.isRequest
        resolve(true);
      });
    },
    rejectValue() {
      return new Promise(resolve => {
        this.isReject = !this.isReject
        resolve(true);
      });
    },
    saveWorkPermitCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.sopWorkPermitId = result.data
      this.getDetail('save');
    },
    requestWorkPermitCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.sopWorkPermitId = result.data
      this.getDetail('save');
    },
    rejectWorkPermitCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.sopWorkPermitId = result.data
      this.getDetail('save');
    },
    returnDialogOpen() {
      this.returnDialog.show = true;
    },
    closeDialog() {
      this.returnDialog.show = false;
      Object.assign(this.$data.returnDialog, this.$options.data().returnDialog);
    },
    removeWorkPermit() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopWorkPermitId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    approvalWorkPermit() {
      /**
       * 유효성 체크
       *  1. 작업위치 - 2021-12-21 김봉석 유효성 제거 희망
       *  2. 작업자 - 2022-06-07 김봉석 유효성 제거 희망
       *  3. 발급정보
       */
      let isIng = true;
      // if (isIng && (!this.workPermit.workers || this.workPermit.workers.length === 0)) {
      //   window.getApp.$emit('ALERT', {
      //     title: '안내', // 안내
      //     message: '작업자를 지정하시기 바랍니다.', // 작업자를 지정하시기 바랍니다.
      //     type: 'warning', // success / info / warning / error
      //   });
      //   isIng = false;
      // }

      if (isIng) {
        if (!this.workPermit.issuedUserId || !this.workPermit.approvalUserId) {
          window.getApp.$emit('ALERT', {
            title: '안내', 
            message: '허가서의 발급정보가 입력되지 않았습니다.', 
            type: 'warning', // success / info / warning / error
          });
          isIng = false;
        } 
        // else {
        //   if (this.$_.findIndex(this.workPermit.supWorks, supWork => {
        //     return !supWork.issuedUserId || !supWork.approvalUserId
        //   }) > -1) {
        //     window.getApp.$emit('ALERT', {
        //       title: '안내', // 
        //       message: '발급정보가 입력되지 않은 보충작업이 있습니다.', 
        //       type: 'warning', // success / info / warning / error
        //     });
        //     isIng = false;
        //   }
        // }
      }

      if (isIng) {
        this.$refs['editForm'].validate().then(_result => {
          if (_result) {
            eventBus.$emit('vaildForm', 'appr')
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      }
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    // [S] 결재관련 
    approvalWorkPermitCallback(result) { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    // [E]] 결재관련 
    // 출력물 다운로드 샘플
    printWorkPermit() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.popupParam.sopWorkPermitId);
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          let fileOrgNm = this.workPermit.workSummary + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    }
  }
};
</script>
<style lang="sass">
.work-card
  .customDate,.customText
    padding-bottom: 0px !important
  .work-item
    padding-top:18px !important
    .q-field__inner
      padding-bottom: 0px !important
  label
    padding-bottom: 0px !important
.work-title-text
  font-size: 15px
  font-weight: 800
.bg-highLight
  border-width: 3px !important
  border-style: dashed !important
  border-color: hsla(0, 95%, 35%, 1) !important
.work-permit-table
  .default-table
    margin-bottom: 0px
</style>